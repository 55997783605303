import { Backdrop, Box, Button, Chip, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Switch, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { Cancel } from '@mui/icons-material';
import axios from '../../../api/axios';
import { useEffect, useState } from 'react';
import Notify from '../../../features/helpers/notifyConfig';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
// import { addPoc } from './Contracts';

export const QuoteForm = ({ data, setData, companies, type = "", projectPricing }) => {
  // console.log(data);
  const [loader, setLoader] = useState('none');
  const [currentAmount, setCurrentAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [prjctPrice, setPrjctPrice] = useState(null);
  const [servprods, setServProds] = useState(null);
  // console.log(data);

  useEffect(() => {
    const getProducts = async () => {
      await axios.get("/quote-generation/get-products-services",
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + localStorage.getItem('access'),
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors'
          }
        }
      ).then((response) => {
        console.log(response?.data?.message);
        setServProds(response?.data?.message);
      }).catch((error) => {
        console.log("Cannot able to get products")
      });
    }

    const getProjectPricingDetails = async () => {
      await axios.get(`/quote-generation/pricing`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + localStorage.getItem('access')
          }
        }).then((response) => {
          setPrjctPrice(response?.data?.message);
        }).catch((error) => {
          Notify.error("Failed to fetch Project Information!");
        })
    }

    setData((prevState) => ({
      ...prevState,
      project: ""
    }))
    getProducts();
    getProjectPricingDetails();
  }, [])

  // Get Calculated Pricing based on entered item value
  const getTotalPricing = async () => {
    const finData = {
      "_id": prjctPrice[data.project]?._id,
      "no_of_items": data.item
    }
    setOpen(true);

    await axios.post
      ("/quote-generation/calculation", JSON.stringify(finData),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            withCredentials: true,
            mode: 'no-cors',
            'Authorization': `Bearer ` + localStorage.getItem('access')
          }
        }).then((response) => {
          setCurrentAmount(response?.data?.amount);
        }).catch((error) => {
          // console.log(error.response);
          Notify.error("Failed to fetch total price!");
        }).finally(() => {
          setOpen(false);
        })
  }

  const handleAddInputData = (e) => {
    let { value, id, name } = e.target;
    if (id == 'total_additional_charges' || id == 'discount') value = value != "" ? Number(value) : "";

    else if (name == "company_id") {
      setLoader('flex');
      const getCompanyProducts = async () => {
        await axios.get(`/quote-generation/product/get-by-company-name/${value}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + localStorage.getItem('access')
            }
          }).then((response) => {
            setData((prevState) => ({
              ...prevState,
              products: []
            }));
            const finData = response?.data?.message;
            const all_prods = finData.products ?? {};
            const all_servs = finData.services ?? {};

            var prod_len = 0, serv_len = 0;
            Object.keys(all_prods).map((ind, val) => {
              prod_len++;
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": all_prods[ind]['product_name'],
                      "product_code": all_prods[ind]['product_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "license_type": all_prods[ind]['license_type'],
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })

            Object.keys(all_servs).map((ind, val) => {
              serv_len++;
              console.log("Services")
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": all_servs[ind]['service_name'],
                      "product_code": all_servs[ind]['service_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })

            setLoader('none');
            Notify.success((prod_len) + " Products and " + (serv_len) + " Services" + " addded!");
          }).catch((error) => {
            console.log(error.response)
            setLoader('none');
            if (error?.response?.data?.message == "Error fetching product/services automatically. You may have to insert manually") {
              Notify.warning("Select product/services manually for this company")
            }
            else {
              Notify.error("Some error occurred while fetching product/services")
            }
          })
      }
      getCompanyProducts();
    }

    if (name === "item" || name === "project") {
      if (currentAmount !== null)
        setCurrentAmount(null);
    }
    const findata = (name === "company_id" || name === "project") ? name : id;
    setData((prevState) => ({
      ...prevState,
      [findata]: value,
    }));
  };

  const addProductCard = (pname = "", pcode = "", plicense = "") => {
    const updated = [...data.products];
    updated.unshift({
      "product_name": pname,
      "product_code": pcode,
      "qty": 1,
      "id": "",
      "duration": "1 Year",
      "license_type": plicense,
      "product_price": 0,
      "net_total": 0,
    });
    console.log(updated);
    setData((prevState) => ({
      ...prevState,
      products: updated,
    }));
  };

  const handleProducts = (e, key, field) => {
    const { value, name } = e.target;
    const updated = [...data.products];
    // alert(typeof(value));

    if (field == "qty" || field == "product_price") updated[key][field] = value != "" ? Number(value) : "";
    else if (field == "duration") {
      let currDuration = updated[key][field];
      let durationArray = currDuration.split(" ");
      // console.log(durationArray[0]);
      if (name == "duration_num") {
        console.log(value);
        updated[key][field] = value + " " + durationArray[1];
      }
      else if (name == "duration_type")
        updated[key][field] = durationArray[0] + " " + value;
    }
    else if (field == "product_name") {
      var flag = false;
      Object.keys(servprods?.services).map((ind, val) => {
        if (ind == value) {
          flag = true;
          updated[key]["service_name"] = servprods?.services[ind]['service_name'];
          updated[key]["service_code"] = servprods?.services[ind]['service_code'];
          updated[key]["id"] = ind;
        }
      }
      )

      if (!flag) {
        Object.keys(servprods?.products).map((ind, val) => {
          if (ind == value) {
            updated[key]["product_name"] = servprods?.products[ind]['product_name'];
            updated[key]["product_code"] = servprods?.products[ind]['product_code'];
            updated[key]["license_type"] = servprods?.products[ind]['license_type'];
            updated[key]["id"] = ind;
          }
        }
        )
      }

      setLoader('flex');
      const getLinkedProducts = async () => {
        await axios.get(`/quote-generation/product/get/${value}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + localStorage.getItem('access')
            }
          }).then((response) => {
            const finData = response?.data?.message;
            console.log(finData);
            var len = 0;
            Object.keys(finData).map((ind, val) => {
              len++;
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": finData[ind]['product_name'],
                      "product_code": finData[ind]['product_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "license_type": finData[ind]['license_type'],
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })
            setLoader('none');
            Notify.success((len - 1) + " related Products addded!");
          }).catch((error) => {
            console.log(error.response)
          })
      }
      getLinkedProducts();
    }

    updated[key]["net_total"] = updated[key]["qty"] * updated[key]["product_price"];

    setData((prevState) => ({
      ...prevState,
      products: updated,
    }));
  };

  const removeProduct = (index) => {
    setData((prevState) => ({
      ...prevState,
      products: [
        ...prevState.products.filter((val, key) => {
          return key != index;
        })
      ]
    }))
  }

  const handleSwitchChange = (event) => {
    setData(prevState => ({
      ...prevState,
      create_new_version: event.target.checked
    }))
  };

  if (servprods === null || prjctPrice === null) {
    return (
      <Stack direction="row">
        <Grid position='relative' container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} sx={{ borderRadius: "3px" }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} sx={{ borderRadius: "3px" }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} sx={{ borderRadius: "3px" }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} sx={{ borderRadius: "3px" }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} sx={{ borderRadius: "3px" }} />
          </Grid>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 2rem" }} />
        <Stack direction="column" minWidth="25rem" spacing={2}>
          <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
          <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <Stack direction="row">
        <Grid position='relative' container spacing={2} mt={1}>
          <Grid item xs={12} height='100%' width='100%' display={loader} alignItems='center' justifyContent='center' position='absolute' sx={{ zIndex: '77', backdropFilter: 'blur(2px)', background: 'rgba(255, 255, 255, 0.06)', boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
            <CircularProgress />
          </Grid>
          <Grid item xs={12} sm={6}>
            {
              type == "" ?
                <FormControl fullWidth>
                  <InputLabel id="complabel">Select Company</InputLabel>
                  <Select
                    labelId="complabel"
                    name="company_id"
                    id="company_id"
                    value={data.company_id}
                    label="Select Company"
                    sx={{ height: "47px" }}
                    onChange={handleAddInputData}
                  >
                    {Object.keys(companies).map((key, val) =>
                      <MenuItem key={val} className='status' value={key}>{companies[key]['company_name']}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                :
                <TextField fullWidth id="company_id" value={data.company_name} label="Company Name" variant="outlined" />
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={data.quote_date}
              id="quote_date"
              label="Date"
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleAddInputData}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={data.quote_expiration_date}
              id="quote_expiration_date"
              label="Expiration Date"
              fullWidth
              onChange={handleAddInputData}
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={data.total_additional_charges}
              id="total_additional_charges"
              label="Total Additional Charges"
              type='number'
              fullWidth
              inputProps={{ inputMode: "numeric" }}
              onChange={handleAddInputData}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={data.discount}
              id="discount"
              label="Discount"
              type='number'
              fullWidth
              onChange={handleAddInputData}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={data.contact_address}
              id="contact_address"
              label="Company Address"
              fullWidth
              onChange={handleAddInputData}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            />
          </Grid>

          {/* PROJECTS */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="project">Select Project</InputLabel>
              <Select
                labelId="project"
                name="project"
                id="project"
                value={data.project}
                label="Select Project"
                sx={{ height: "47px" }}
                onChange={handleAddInputData}
              >
                {servprods?.all_pricing_projects.map((project) =>
                  <MenuItem key={project} value={project}>{project}</MenuItem>
                ) ?? []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {prjctPrice && data.project !== ""
              ?
              <Grid container alignItems="end">
                <Grid item xs={9} direction="row">
                  <TextField
                    value={data.item}
                    id="item"
                    name='item'
                    label={`Enter number of ${prjctPrice[data.project]?.item}`}
                    fullWidth
                    onChange={handleAddInputData}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ textDecoration: "underline", cursor: (data.item && !currentAmount) ? "pointer" : "default", color: (data.item && !currentAmount) ? "#1b2c58" : "lightgray" }}
                    textAlign="end"
                    variant='body2'
                    onClick={(data.item && !currentAmount) ? getTotalPricing : () => { }}
                  >
                    Check Price</Typography>
                </Grid>
              </Grid>
              : <></>}
          </Grid>
          {
            currentAmount
              ? <>
                <Grid item xs={12}>
                  <Typography bgcolor="#8fdf82" sx={{ border: "solid 1px", borderRadius: "5px", padding: "5px 10px !important" }} textAlign="center" variant='body2'>Calculated Price is coming out as <b>{currentAmount}</b> for {`${data.item} ${prjctPrice[data.project]?.item}`}</Typography>
                </Grid>
              </>
              : <></>
          }
          {
            type == ""
              ? <></>
              : <Grid item xs={12}>
                <FormControlLabel control={<Switch id='accessSharepoint' checked={data.create_new_version} onChange={handleSwitchChange} />}
                  label={
                    <Typography fontWeight="600" fontFamily="Segoe UI" textAlign="left">
                      Create New Version of this Quote
                    </Typography>
                  } />
              </Grid>
          }

          <Grid item xs={12}>
            <Typography fontWeight="600" mt={3} textAlign="left">
              Add Products/Services &nbsp;&nbsp;
              <IconButton
                size="small"
                sx={{
                  backgroundColor: "#C7C8CC",
                  "&:hover": {
                    backgroundColor: "#B4B4B8",
                  },
                }}
                onClick={addProductCard}
              >
                <AddIcon fontSize="7px" />
              </IconButton>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {data.products.map((val, index) => (
              <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem", position: 'relative' }}>
                <Grid mt={0} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="prodlabel">Select Product/Service</InputLabel>
                      <Select
                        labelId="prodlabel"
                        name="product_name"
                        id="product_name"
                        value={val.id ?? ""}
                        label="Select Product/Service"
                        sx={{ height: "47px" }}
                        onChange={(e) => handleProducts(e, index, "product_name")}
                      >
                        {Object.keys(servprods?.products).map((key, val) =>
                          <MenuItem key={val} className='prods' value={key}>{servprods?.products[key]['product_name']}</MenuItem>
                        )}
                        {Object.keys(servprods?.services).map((key, val) =>
                          <MenuItem key={val} className='servs' value={key}>{servprods?.services[key]['service_name']}</MenuItem>
                        )}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={val.qty ?? ""}
                      id={`qty_${index}`}
                      label="Quantity"
                      type='number'
                      fullWidth
                      onChange={(e) => handleProducts(e, index, "qty")}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container columnSpacing={2} direction='row'>
                      <Grid item sm={6}>
                        <TextField
                          value={Number(val.duration.split(" ")[0]) ?? ""}
                          name='duration_num'
                          id={`duration_${index}`}
                          label="Duration"
                          fullWidth
                          type='number'
                          inputProps={{ inputMode: "numeric" }}
                          onChange={(e) => handleProducts(e, index, "duration")}
                        />
                      </Grid>

                      <Grid item sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="durationType">Type</InputLabel>
                          <Select
                            labelId="durationType"
                            value={val.duration.split(" ")[1] ?? ""}
                            label="Type"
                            name='duration_type'
                            id={`duration_type_${index}`}
                            sx={{ height: "47px" }}
                            onChange={(e) => handleProducts(e, index, "duration")}
                          >
                            <MenuItem value='Year'>Year</MenuItem>
                            <MenuItem value='Month'>Month</MenuItem>
                            <MenuItem value='Day'>Day</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      value={val.product_price ?? ""}
                      id={`product_price_${index}`}
                      label="Product Price"
                      type='number'
                      fullWidth
                      onChange={(e) => handleProducts(e, index, "product_price")}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={val.net_total ?? ""}
                      id={`net_total_${index}`}
                      label="Net Total"
                      type='number'
                      fullWidth
                      disabled
                      onChange={(e) => handleProducts(e, index, "net_total")}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item textAlign='end' xs={6}>
                    <Button variant='outlined' onClick={() => removeProduct(index)} color="error">
                      Remove
                    </Button>
                  </Grid>

                </Grid>
              </Paper>
            ))}
          </Grid>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 2rem" }} />
        <Box minWidth="25rem">
          <Typography mb={3} variant='h6'>Pricing based on number of {(prjctPrice && data.project) ? (prjctPrice[data.project]?.item) : "items"}</Typography>

          {
            (prjctPrice && data.project !== "")
              ? <Box>
                <Stack direction="column">
                  {/* MINIMUM PRICING */}
                  <Stack direction="row" spacing={1} sx={{ border: "solid 1px #1034a6", textAlign: "center", borderRadius: "5px" }} mb={2} py={0.7} px={2} color="#1034a6">
                    <Grid container textAlign="left">
                      <Grid item xs={8}>
                        <Typography>{prjctPrice[data.project]?.minimum?.upto_item}:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "600" }}>{prjctPrice[data.project]?.minimum?.value}
                          <i style={{ fontWeight: "400", fontSize: "0.8rem" }}> total
                          </i>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>

                  {prjctPrice[data.project]?.range_of_items.map((item, index) =>
                    <Stack direction="row" spacing={1} key={index} sx={{ border: "solid 1px", textAlign: "center", borderRadius: "5px" }} mb={2.5} py={0.7} px={2}>
                      <Grid container textAlign="left">
                        <Grid item xs={8}>
                          <Typography>{item['upto_item']}:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "600" }}>{item['value']}
                            <i style={{ fontWeight: "400", fontSize: "0.8rem" }}>
                              / {prjctPrice[data.project]?.item.endsWith("s") ? prjctPrice[data.project]?.item.slice(0, -1)?.toLowerCase() : prjctPrice[data.project]?.item?.toLowerCase()}
                            </i>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}

                  {/* MAXIMUM PRICING */}
                  <Stack direction="row" spacing={1} sx={{ border: "solid 1px darkgreen", textAlign: "center", borderRadius: "5px", color: "#198f51" }} mb={2.5} py={0.7} px={2}>
                    <Grid container textAlign="left">
                      <Grid item xs={8}>
                        <Typography>{prjctPrice[data.project]?.other?.upto_item}:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "600" }}>{prjctPrice[data.project]?.other?.value}
                          <i style={{ fontWeight: "400", fontSize: "0.8rem" }}>
                            / {prjctPrice[data.project]?.item.endsWith("s") ? prjctPrice[data.project]?.item.slice(0, -1)?.toLowerCase() : prjctPrice[data.project]?.item?.toLowerCase()}
                          </i>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>

                </Stack>
              </Box>
              : <>
                <Typography>Please select a project to see the respective item price listing</Typography>
                <Box textAlign="center" mt={1}><ArrowCircleLeftOutlinedIcon /></Box>
              </>
          }
        </Box>

      </Stack>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

