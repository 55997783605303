import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NodataFound } from '../NodataFound'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import axios from '../../api/axios';
import Notify from '../../features/helpers/notifyConfig';
// import { advancedDate } from '../Events';

const advancedDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDay = date.getDate();
    const formattedMonth = monthNames[date.getMonth()];
    const formattedYear = date.getFullYear();
    return `${formattedDay} ${formattedMonth} ${formattedYear}`;
};

export const leave_type = {
    'full day': 'Full Day',
    'half day': 'Half Day'
}

export const ManageLeave = ({ open, setOpen, data, getLeaveRequests, setLoad }) => {
    const [decision, setDecision] = useState({
        "id": "",
        "leave_id": "",
        "description": "",
        "response": ""
    });

    const sendResponse = (val, msg, type) => {
        setDecision(prevState => ({
            ...prevState,
            "id": val.id,
            "leave_id": val.leave_requests[0].leave_id,
            "description": msg,
            "response": type,
        }))
    }

    useEffect(() => {
        const reviewLeave = async () => {
            await axios.post(`/dashboard/timesheet/leave/review`,
                JSON.stringify(decision),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    // console.log(response);
                    getLeaveRequests();
                    setLoad(true);
                    Notify.success(decision.description);
                }).catch((error) => {
                    console.log(error.response);
                    Notify.error("Some error occurred. Try Again!")
                })
        }

        if(decision.id!="") reviewLeave();
    }, [decision])

    return (
        <Dialog maxWidth='lg' fullWidth='true' onClose={() => setOpen(false)} open={open}>
            <DialogTitle id="alert-dialog-title" sx={{ color: 'primary.main' }}>Manage Leave Requests</DialogTitle>
            <DialogContent sx={{ minHeight: '60vh' }}>
                {
                    data.length != 0 ? data.map((val, key) => (
                        val.leave_requests.map((vaal, keey) =>(
                        <Box mb={1.5} key={vaal.leave_id}>
                            <Accordion style={{
                                // background: 'rgb(27,44,88)',
                                // background: 'linear-gradient(90deg, rgba(27,44,88,1) 46%, rgba(93,55,29,1) 100%)'
                                background: 'rgb(27, 44, 88)',
                                background: 'linear-gradient(90deg, rgba(27,44,88,0.9080882352941176) 46%, rgba(93,55,29,0.891281512605042) 100%)'
                            }}
                                sx={{ width: '100%', color: 'white' }} key={key}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid container alignItems="center" spacing={6}>
                                        <Grid item><Typography variant='subtitle1'><strong>Name: </strong>{vaal.first_name + " " + vaal.last_name}</Typography></Grid>
                                        <Grid item><Typography variant='subtitle1'><strong>Duration: </strong>{vaal.leave_type}</Typography></Grid>
                                        <Grid item><Typography variant='subtitle1'><strong>Leave From: </strong>{advancedDate(vaal.start_date)}</Typography></Grid>
                                        <Grid item><Typography variant='subtitle1'><strong>Leave Days: </strong>{vaal.effective_no_of_days}</Typography></Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant='h6'>Reason of Leave:</Typography>
                                    <Typography>{val.leave_requests[0].reason}</Typography>
                                    <Stack direction="row" gap={2} justifyContent="end">
                                        <Button
                                            onClick={() => sendResponse(val,"Leave has been rejected", "rejected")}
                                            sx={{ background: "#d84d4d", border: "solid 2px white" }}
                                            variant='contained'
                                            size='small'
                                        >Reject &nbsp;<ClearIcon /></Button>
                                        <LoadingButton
                                            onClick={() => sendResponse(val,"Leave has been accepted", "accepted")}
                                            // loading={approvemsg}
                                            // disabled={returnmsg}
                                            variant="contained"
                                            sx={{ background: "#79AC78", border: "solid 2px white" }}
                                            size='small'
                                        >Accept &nbsp;<CheckIcon /></LoadingButton>

                                        {/* <Button onClick={() => submitReturn(data.employeeSheetID, data.managerSheetID, "approve")}
                       sx={{ background: "#79AC78" }} variant='contained' size='small'>Approve &nbsp;<CheckIcon /></Button> */}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                )))) : <NodataFound msg="No Leave Requests Found!" />
                }
            </DialogContent>
        </Dialog>
    )
}
