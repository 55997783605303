import { Accordion, Grid, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip, Typography, AccordionDetails, Chip, Badge } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Notify from '../../features/helpers/notifyConfig'
import axios from '../../api/axios'
import { DateRangePicker } from 'rsuite';
import { isLead } from '../../components/auth/userAuth'
import { ManageLeave, leave_type } from './ManageLeave'
import { NodataFound } from '../NodataFound';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Scrollbars } from 'react-custom-scrollbars';
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../../Router";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApplyLeaveBox from './ApplyLeaveBox';

const ColoredBox = (label, value, textcolor, boxcolor) => {
    return (
        <Box sx={{ backgroundColor: boxcolor, border: `solid 1px ${textcolor}`, width: 'max-content', padding: '3px 10px', borderRadius: '5px' }}>
            <Typography variant='subtitle1' fontWeight='600' sx={{ color: textcolor }}>{label}: <span style={{ color: 'black', fontWeight: '500' }}>{value}</span></Typography>
        </Box>
    )
}

export const LeaveIndex = () => {
    const [leaveData, setLeaveData] = useState(null);
    const [leaveRequestData, setLeaveRequestData] = useState(null);
    const [leaveApplyBox, setLeaveApplyBox] = useState(false);
    const [openPTO, setOpenPTO] = useState(false);
    const [load, setLoad] = useState(false);
    const [openLeaveBox, setOpenLeaveBox] = useState(false);
    const [value, setValue] = useState(1);
    const [data, setData] = useState(null);
    const [ptostat, setStat] = useState("FALSE");
    const [applyLeaveBox, setApplyLeaveBox] = useState(false);

    const { pTimesheet } = currentUser();

    const openPTOBox = () => {
        setOpenPTO(true);
    }

    const getData = async () => {
        await axios.get("/dashboard/timesheet/leave/all",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                // console.log(response);
                setLeaveData(response?.data);
                setData(response?.data?.leave_requests);
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!")
            })
    }

    const checkPTO = async () => {
        await axios.get("/dashboard/timesheet/leave/pto",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                // console.log(response.data)['PTO Submitted'];
                setStat(response.data['PTO Submitted']);
                if (response.data['PTO Submitted'] == "TRUE") getData();
                else Notify.warning("Your PTO hours are not set yet. Please contact the Portal team!")
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!")
            })
    }

    const getLeaveRequests = async () => {
        await axios.get("/dashboard/timesheet/leave/review",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response?.data);
                setOpenLeaveBox(true);
                setLeaveRequestData(response?.data);
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!")
            })
    }

    useEffect(() => {
        checkPTO();
    }, [])

    useEffect(() => {
        if (load) {
            getData();
            setLoad(false);
        }
    }, [load])

    return (
        <Box sx={{ margin: '1.5rem 2rem' }}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
                <Stack direction='row' spacing={2}>
                    <Box>{ColoredBox("Available leaves", leaveData ? leaveData.pto : '-', '#4769c3', '#CCDAFF')}</Box>
                    <Box>{ColoredBox("Pending Request", leaveData ? leaveData.NoOfPendingRequests : '-', '#dca438', '#FFF2D9')}</Box>
                    <Box>{ColoredBox("Rejected leaves", leaveData ? leaveData.NoOfRejectedRequests : '-', '#f04949', '#FFDADA')}</Box>
                </Stack>

                {showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet, ['Review Leave']), <Button sx={{ position: 'relative' }} onClick={getLeaveRequests} variant='contained'>
                    Leave Request
                    {leaveData ? <Box bgcolor='#d84d4d' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '25px', height: '25px', top: '-10px', left: '-10px', borderRadius: '50%' }}>
                        <Typography fontWeight='600' fontSize='10px'>{leaveData.PendingLeaveRequests}</Typography>
                    </Box> : <></>}
                </Button>, <></>)}
            </Stack>

            {data ?
                <>
                    {showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet, ['Add Leave']),
                        <Box mt={4}>
                            <Button
                                color='success'
                                variant='contained'
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={()=> setLeaveApplyBox(true)}
                                >
                                Apply for leave
                            </Button>
                        </Box>
                    )}

                    {showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet, ['View All Leaves']),
                        <Box mt={2} border='solid 1px' borderRadius='5px' overflow='hidden' borderColor='black'>
                            <Box px={2} py={1} bgcolor='#657EBF' color='primary.white'><Typography variant='h6'>Leaves</Typography></Box>
                            <Box p={3} height='60vh' overflow='auto'>
                                <Scrollbars>
                                    {
                                        data.length != 0 ? data.map((val, key) => (
                                            <Box mb={2} pr={2}>
                                                <Accordion
                                                    sx={{ width: '100%', color: 'white', backgroundColor: '#bbc6e4b5' }} key={key}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Grid container alignItems="center">
                                                            <Typography color='black' fontFamily='Segoe UI' position='absolute' left={10} variant='subtitle1'><strong>Type: </strong>{leave_type[val.leave_type]}</Typography>
                                                            <Typography color='black' fontFamily='Segoe UI' position='absolute' left={200} variant='subtitle1'><strong>Leave From: </strong>{val.start_date}</Typography>
                                                            <Typography color='black' fontFamily='Segoe UI' position='absolute' left={450} variant='subtitle1'><strong>Total Days: </strong>{val.no_of_days}</Typography>
                                                            <Box position='absolute' left={650}><Chip size='small' sx={{ boxShadow: '-4px 3px 0px white' }} label={val.status} color="primary" /></Box>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant='h6' color='black'>Reason of Leave:</Typography>
                                                        <Typography color='black'>{val.reason}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            // </Scrollbars>
                                        )) : <NodataFound msg="No Leave History Found!" />
                                    }
                                </Scrollbars>
                            </Box>
                        </Box>
                    )}

                </>
                : <></>}

            {leaveRequestData ? <ManageLeave open={openLeaveBox} setOpen={setOpenLeaveBox} data={leaveRequestData.leave_requests} getLeaveRequests={getLeaveRequests} setLoad={setLoad} /> : <></>}

            {leaveApplyBox && 
            <ApplyLeaveBox setLeaveApplyBox={setLeaveApplyBox} leaveData={leaveData} />}

            {/* Set Available Leaves here */}
        </Box>
    )
}
