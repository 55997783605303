import { Label } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, IconButton, InputAdornment, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteIcon } from "../../../components/Icons/DeleteIcon";
import axios from "../../../api/axios";
import Notify from "../../../features/helpers/notifyConfig";
import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function ProjectsForm({ setOpenProject }) {
    const [submit, setSubmit] = useState(false);
    const [prjctPrice, setPrjctPrice] = useState(null);
    const [servprods, setServProds] = useState(null);
    // const [largestItem, setLargestItem] = useState(null);

    useEffect(() => {
        const getProducts = async () => {
            await axios.get("/quote-generation/get-products-services",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access'),
                        'Access-Control-Allow-Origin': '*',
                        mode: 'no-cors'
                    }
                }
            ).then((response) => {
                setServProds(response?.data?.message);
            }).catch((error) => {
                console.log("Cannot able to get products")
            });
        }

        const getProjectPricingDetails = async () => {
            await axios.get(`/quote-generation/pricing`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    setPrjctPrice(response?.data?.message);
                }).catch((error) => {
                    Notify.error("Failed to fetch Project Information!");
                })
        }

        getProducts();
        getProjectPricingDetails();
    }, [])

    const [data, setData] = useState({
        "project": "",
        "item": "",
        "minimum": {
            "upto_item": "",
            "value": null
        },
        "other": {
            "upto_item": "",
            "value": null
        },
        "range_of_items": [
            {
                "upto_item": "",
                "value": null
            }
        ]
    });

    const addItemsCard = (pname = "", pcode = "", plicense = "") => {
        const updated = [...data.range_of_items];
        updated.unshift({
            "upto_item": "",
            "value": null
        });
        setData((prevState) => ({
            ...prevState,
            range_of_items: updated,
        }));
    };

    const handleItems = (e, key, field, extra = "") => {
        const { value, name } = e.target;

        if (key !== -1) {
            const updated = [...data.range_of_items];
            updated[key][field] = field === "upto_item" ? String(value) : Number(value);
            setData((prevState) => ({
                ...prevState,
                range_of_items: updated,
            }));
        } else {
            setData((prevState) => ({
                ...prevState,
                [extra]: {
                    ...prevState[extra],
                    [field]: field === "upto_item" ? String(value) : Number(value),
                },
            }));
        }
    }

    const removeItem = (index) => {
        setData((prevState) => ({
            ...prevState,
            range_of_items: [
                ...prevState.range_of_items.filter((val, key) => {
                    return key != index;
                })
            ]
        }))
    }

    const onTextFieldChange = (e) => {
        const { value, name } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function checkForLargerValue() {
        let largestItem = null;
        data.range_of_items.map((item) => {
        console.log(item);
            if (largestItem === null || Number(item.upto_item) > largestItem) {
                largestItem = Number(item.upto_item);
            }
        });
        // console.log(largestItem);
        if (largestItem !== null && largestItem !== Number(data.other.upto_item)) {
            setData((prevState) => ({
                ...prevState,
                "other": {
                    ...prevState.other,
                    "upto_item": largestItem,
                },
            }));
        }
    }

    const submitProject = () => {
        const addProject = async () => {
            setSubmit(true);
            await axios.post
                ("/quote-generation/pricing", JSON.stringify(data),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            withCredentials: true,
                            mode: 'no-cors',
                            'Authorization': `Bearer ` + localStorage.getItem('access')
                        }
                    }).then((response) => {
                        setOpenProject(false);
                        Notify.success("Project Added Successfully!")
                    }).catch((error) => {
                        // console.log(error.response);
                        Notify.error("Error Occurred. Try Again!");
                    }).finally(() => {
                        setSubmit(false);
                    })
        }

        let flag = false;
        // console.log(data.project);
        let projectExists = servprods?.all_pricing_projects?.some(item => item.toLowerCase() === data.project.toLowerCase()) ?? false;
        let itemExists = prjctPrice?.[capitalizeFirstLetter(data.project)]?.item.toLowerCase() === data.item.toLowerCase() ?? false;

        data.range_of_items.map((proj, index) => {
            if (proj.upto_item === "" || proj.value === null) {
                flag = true;
            }
        })

        if (data.project === "" || data.item === null) flag = true;
        if (data.minimum.upto_item === "" || data.minimum.value === null) flag = true;
        if (data.other.upto_item === "" || data.other.value === null) flag = true;

        if (projectExists && itemExists)
            Notify.warning("This Project and Item name has been already taken!")
        else if (flag) {
            Notify.warning("All data needs to be filled!")
        }
        else
            addProject();
    }

    if (servprods === null || prjctPrice === null) {
        return (
            <Grid position='relative' container spacing={3} mt={1}>
                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={60} sx={{ borderRadius: "3px" }} />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid position='relative' container columnSpacing={3} rowSpacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    // value={data.quote_date}
                    id="project"
                    name="project"
                    label="Project Name"
                    placeholder="Enter project name"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={onTextFieldChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    // value={data.quote_date}
                    id="item"
                    name="item"
                    label="Item Name"
                    fullWidth
                    placeholder="certificates, signings etc."
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={onTextFieldChange}
                />
            </Grid>
            {/* <Grid direction="row" item xs={12}>
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Label></Label>
                    <Typography variant="body1" mb={0.5}>Price per item if items exceeds the maximum count</Typography>
                    <TextField
                        required
                        // value={data.quote_date}
                        id="item"
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    // onChange={handleAddInputData}
                    />
                </Stack>
            </Grid>
            <Grid direction="row" item xs={12}>
                <Stack direction="row" spacing={2}>
                    <Label></Label>
                    <Typography variant="body1" mb={0.5}>Total Price for minimum or less than minimum number of items</Typography>
                    <TextField
                        required
                        // value={data.quote_date}
                        id="item"
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    // onChange={handleAddInputData}
                    />
                </Stack>
            </Grid> */}

            <Grid item xs={12}>
                <Typography fontWeight="600" mt={3} textAlign="left">
                    Set Range and pricing of items accrodingly &nbsp;&nbsp;
                    <IconButton
                        size="small"
                        sx={{
                            backgroundColor: "#C7C8CC",
                            "&:hover": {
                                backgroundColor: "#B4B4B8",
                            },
                        }}
                        onClick={addItemsCard}
                    >
                        <AddIcon fontSize="7px" />
                    </IconButton>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {/* MINIMUM */}
                <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem", position: 'relative', background: "#1034a62e" }}>
                    <Grid mt={0} container columnSpacing={5} rowSpacing={2} alignItems="end">
                        <Grid item xs={6}>
                            <TextField
                                value={data.minimum.upto_item ?? ""}
                                required
                                label="Minimum count of items"
                                fullWidth
                                type="number"
                                variant="standard"
                                inputProps={{ inputMode: "numeric" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleItems(e, -1, "upto_item", "minimum")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={data.minimum.value ?? ""}
                                label="Total Price (in dollars)"
                                fullWidth
                                required
                                variant="standard"
                                type="number"
                                inputProps={{ inputMode: "numeric" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleItems(e, -1, "value", "minimum")}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {data.range_of_items.map((val, index) => (
                    <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem", position: 'relative' }}>
                        <Grid mt={0} container columnSpacing={4} rowSpacing={2} alignItems="end">
                            <Grid item xs={5}>
                                <TextField
                                    value={val.upto_item ?? ""}
                                    label="No. of items upto"
                                    fullWidth
                                    type="number"
                                    required
                                    variant="standard"
                                    inputProps={{ inputMode: "numeric" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlurCapture={checkForLargerValue}
                                    onChange={(e) => handleItems(e, index, "upto_item")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    value={val.value ?? ""}
                                    label="Price per item (in dollars)"
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    required
                                    inputProps={{ inputMode: "numeric" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleItems(e, index, "value")}
                                />
                            </Grid>
                            <Grid item textAlign='end' xs={2}>
                                <Button
                                    onClick={() => removeItem(index)}
                                    disabled={index === 0}
                                    color="error">
                                    <DeleteOutlineIcon />
                                </Button>
                            </Grid>

                        </Grid>
                    </Paper>
                ))}

                {/* MAXIMUM */}
                <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem", position: 'relative', background: "#cbebcb" }}>
                    <Grid mt={0} container columnSpacing={5} rowSpacing={2} alignItems="end">
                        <Grid item xs={6}>
                            <TextField
                                value={data.other.upto_item ?? ""}
                                label="Beyond maximum count of items"
                                fullWidth
                                type="number"
                                required
                                variant="standard"
                                inputProps={{ inputMode: "numeric" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                                onChange={(e) => handleItems(e, -1, "upto_item", "other")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={data.other.value ?? ""}
                                label="Price per item (in dollars)"
                                fullWidth
                                required
                                variant="standard"
                                type="number"
                                inputProps={{ inputMode: "numeric" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleItems(e, -1, "value", "other")}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <LoadingButton variant='contained'
                color="primary"
                loading={submit}
                style={{ marginLeft: "auto", padding: "0.2rem 1.5rem" }}
                onClick={submitProject}
            >Add</LoadingButton>

        </Grid>
    )
}