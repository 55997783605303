import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Token } from '../../constants'
import { SingleTable } from '../../features/components/Table/SingleTable'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Notify from '../../features/helpers/notifyConfig';
import { FormikQuoteTable } from './Tables/FormikQuoteTable';
import { Cancel } from '@mui/icons-material';
import { QuoteForm } from './Forms/QuoteForm';
import { LoadingButton } from '@mui/lab';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import ProjectsForm from './Forms/ProjectsForm';

// const initialProd = {
//   "product_name": "",
//   "product_code": "",
//   "id": "",
//   "qty": 1,
//   "duration": "",
//   "license_type": "",
//   "product_price": 0,
//   "net_total": 0,
// }

export const Quotes = () => {
  const [quotes, setQuotes] = useState();
  const [open, setOpen] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editAccount, setEditAccount] = useState("");
  const [companies, setCompanies] = useState();
  const [projectPricing, setProjectPricing] = useState(null);

  const [submitLoad, setSubmitLoad] = useState(false);
  const [data, setData] = useState({
    "company_id": "",
    "quote_date": "",
    "quote_expiration_date": "",
    "total_additional_charges": 0,
    "discount": 0,
    "contact_address": "",
    "products": [],
    "project": "",
    "item": ""
  });

  const handleClose = () => {
    setOpen(false);
    
  };

  // 1. Get All Companies
  const getCompanies = async () => {
    setSubmitLoad(true);
    await axios.get("/quote-generation/company/get",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access'),
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
        }
      }
    ).then((response) => {
      // console.log(response?.data?.message);
      setCompanies(response?.data?.message);
    }).catch((error) => {
      console.log("Cannot able to get companies")
    }).finally(() => {
      setSubmitLoad(false);
    })
  }

  // 2. Get Project items pricing
  const getProjectPricingDetails = async () => {
    await axios.get(`/quote-generation/pricing`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        setProjectPricing(response?.data?.message);
      }).catch((error) => {
        Notify.error("Failed to fetch Project Information!");
      })
  }

  const handleClickOpen = (action = "") => {
    setOpen(true);
    setLoading(true);
    if (action == "") {
      setData({
        "company_id": "",
        "quote_date": "",
        "quote_expiration_date": "",
        "total_additional_charges": 0,
        "discount": 0,
        "contact_address": "",
        "products": [],
        "project": "",
        "item": ""
      });
      setEditAccount("");
    }

    getCompanies();
    if (companies) {
      setLoading(false);
    };
  }

  useEffect(() => {
    if (editAccount != "") {
      setData(editAccount);
      handleClickOpen("edit");
    }
  }, [editAccount])

  useEffect(() => {
    if (projectPricing === null)
      getProjectPricingDetails();
  }, [])

  const submitAddForm = (actionType = "") => {
    let hasWarning = false;

    if (data.company_name === "") {
      Notify.warning("Company Name must be mentioned!");
      hasWarning = true;
    }
    else if (data.quote_date === "") {
      Notify.warning("Quote Date must me mentioned!");
      hasWarning = true;
    }
    else if (data.quote_expiration_date === "") {
      Notify.warning("Quote Expiration Date must me mentioned!");
      hasWarning = true;
    }

    if (!hasWarning) {
      const newData = (({ project, item, ...rest }) => rest)(data);

      // a. Adding New Quote
      const addQuote = async () => {
        await axios.post
          ("/quote-generation/quote/add", JSON.stringify(newData),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setOpen(false);
              setLoading(prev => !prev);
              Notify.success("Quote Added Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
      }

      // b. Update Existing Quote
      const editQuote = async () => {
        await axios.put
          ("/quote-generation/quote/update", JSON.stringify(newData),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setOpen(false);
              setLoading(prev => !prev);
              Notify.success("Quote Edited Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
      }

      if (actionType == "edit") editQuote();
      else addQuote();
    }
  }

  // 1. Get All Quotes
  const getQuotes = async () => {
    await axios.get("/quote-generation/quote/get",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access'),
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
        }
      }
    ).then((response) => {
      console.log(response?.data?.message);
      setQuotes(response?.data?.message);
    }).catch((error) => {
      console.log(error.response)
    });
  }

  useEffect(() => {
    getQuotes();
  }, [loading])

  return (
    <Box>
      {
        quotes
          ?
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <LoadingButton loading={submitLoad} onClick={() => handleClickOpen("")} variant='contained' size='small' sx={{ marginBottom: '1rem' }}>Add Quote&nbsp;&nbsp;<AddIcon /></LoadingButton>
              <Button onClick={() => setOpenProject(true)} variant='outlined' size='small' sx={{ marginBottom: '1rem' }}>Add Projects & Pricing</Button>
            </Stack>
            <FormikQuoteTable table_data={quotes} setLoading={setLoading} setEditAccount={setEditAccount} />
            {(companies && !submitLoad) ?
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
                fullWidth
                maxWidth='lg'
              >
                <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Quote</DialogTitle>
                <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                  <QuoteForm projectPricing={projectPricing} data={data} setData={setData} companies={companies} type={editAccount == "" ? "" : "edit"} />
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                  <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="error">
                    <Cancel sx={{ fontSize: "1.8rem" }} />
                  </Button>
                  {
                    editAccount == ""
                      ? <Button variant='contained' onClick={() => submitAddForm()} color="primary">Add</Button>
                      : <Button variant='contained' onClick={() => submitAddForm("edit")} color="primary">Update</Button>
                  }
                </DialogActions>
              </Dialog>
              : <></>
            }

            {/* Project Form */}
            <Dialog
              open={openProject}
              onClose={() => setOpenProject(false)}
              aria-labelledby="edit-apartment"
              fullWidth
              maxWidth='sm'
            >
              <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Projects and their Pricing</DialogTitle>
              <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                <ProjectsForm projectPricing={projectPricing} setOpenProject={setOpenProject} />
              </DialogContent>
              <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={() => setOpenProject(false)} color="error">
                  <Cancel sx={{ fontSize: "1.8rem" }} />
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
          :
          <Stack justifyContent="center" alignItems="center" height="80vh">
            <Box pb={2}>Wait while we gather required information...</Box>
            <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
              <LinearProgress color="inherit" />
            </Stack>
          </Stack>
      }
    </Box>
  )
}
