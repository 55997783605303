import { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import { MainTable } from '../../components/ui/Table/MainTable';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';
import { Form } from '../../components/ui/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import Description from '@mui/icons-material/Description';
import { Link, useParams } from 'react-router-dom';
import { SaleTable } from '../../components/ui/Table/SaleTable';
import Notify from '../../features/helpers/notifyConfig';
import { Cancel, Download } from '@mui/icons-material';
import { GraphReport } from './GraphReport';
import { NewSaleTable } from '../../components/ui/Table/NewSaleTable';
import EditIcon from '@mui/icons-material/Edit';

export const Sale = () => {

    const border = {
        borderRadius: "10px"
    }

    const [currentSales, setcurrentSales] = useState();
    const [graph, setGraph] = useState();
    const [open, setOpen] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const [addData, setaddData] = useState();
    const [otherAction, setotherAction] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, selectType] = useState("Sale");
    const [currentYearTarget, setCurrentYearTarget] = useState(null)
    const [data, setData] = useState({
        Type: "Sale", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", DateSold: "",
        Quarter: Math.ceil((new Date().getMonth() + 1) / 3), Year: new Date().getFullYear(), Stage: "", ProjectSize: "", InvoiceIssueDate: "",
        PaymentTerms: "", DelinquentTerms: "", PaymentStatus: "", PO_Order: "", NotesOnFollow: "", contact_address: ""
    });
    const requested_year = useParams().year;

    const handleClickOpen = () => {
        setOpen(true);
        setLoading(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitAddForm = () => {
        let hasWarning = false;
        if (data.Customer === "") {
            Notify.warning("Customer field cannot be empty!");
            hasWarning = true;
        }
        else if (data.TypeOfProject === "") {
            Notify.warning("Project Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Channel === "") {
            Notify.warning("Channel field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Reseller === "") {
            Notify.warning("Reseller Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.EC_PointOfContact === "") {
            Notify.warning("Choose Point of Contact!");
            hasWarning = true;
        }
        else if (data.PO_Order === "") {
            Notify.warning("PO Order must be uploaded!");
            hasWarning = true;
        }


        if (hasWarning) return;
        setAddForm(true);
    }

    //1. Get Sale info of given year
    const getSaleSalespipeline = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/sale`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setcurrentSales(response.data)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    //2. Get Sale graph info of given year
    const getSaleGraph = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/sale/graph`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setGraph(response.data)
        }).catch((error) => {
            console.log(error.response);
            Notify.error("Graphical Data cannot be fetched. Try Again!")
        });
    }

    //3. Get Add Sale info of given year (for dropdowns)
    const getAddSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/sale/add`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
                setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //4. Add new Sale Pipeline info
    const addNewSalesDetails = async () => {
        await axios.post(`/dashboard/salespipeline/${requested_year}/sale/add`,
            JSON.stringify({ Sale: data }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                Notify.success("New Sale Record Added Successfully!")
                setAddForm(false);
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                setAddForm(false);
                Notify.error("Failed. Try Again!")
            })
    }

    useEffect(() => {
        if (!addForm || !otherAction) {
            getSaleSalespipeline();
            getSaleGraph();
        }

        if (addForm)
            addNewSalesDetails();

    }, [addForm, otherAction])

    useEffect(() => {
        if (open)
            getAddSalespipelineData();
    }, [open])

    const downloadFile = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/excel`,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'blob',
                    // 'Authorization': `Bearer ` + Token.Test
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                }
            }).then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'SalesPipeline.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    return (
        <Box px={4} py={2}>
            {currentSales ?
                <Box>
                    <Grid container spacing={4} display="flex">
                        <Grid item xs md={6} lg={3}>
                            <Paper sx={{ padding: "13px" }} elevation={2}>
                                <Stack direction="row" alignItems="center">
                                    <AttachMoneyIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                    <Box px={2}>
                                        <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Current Year Project Total</Typography>
                                        <Typography variant='subtitle2'>{currentSales.calculation['Current_Year_Total']}</Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs md={6} lg={3}>
                            <Paper sx={{ padding: "13px" }} elevation={2}>
                                <Stack direction="row" alignItems="center">
                                    <MoneyOffIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                    <Box px={2}>
                                        <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Prior Year Project Total</Typography>
                                        <Typography variant='subtitle2'>{currentSales.calculation['Prior_Year_Total']}</Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs md={6} lg={3}>
                            <Paper sx={{ padding: "13px" }} elevation={2}>
                                <Stack direction="row" alignItems="center">
                                    <AdsClickIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                    <Box px={3}>
                                        <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Current Year Target Goal</Typography>
                                        <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
                                            <Typography variant='subtitle2'>{currentSales.calculation['Current_Year_Target_Goal']}</Typography>
                                            <EditIcon sx={{ cursor: 'pointer', "&:hover": { color: "info.main" } }} fontSize='5px' />
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs md={6} lg={3}>
                            <Paper sx={{ padding: "13px" }} elevation={2}>
                                <Stack direction="row" alignItems="center">
                                    <EmojiEventsIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                    <Box px={3}>
                                        <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Goal Achievement</Typography>
                                        <Typography variant='subtitle2'>{currentSales.calculation['Goal_Achievement']}</Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Stack mt={3} spacing={1} direction="row" justifyContent="end" alignItems="center">

                        <LoadingButton
                            onClick={handleClickOpen}
                            endIcon={<AddIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                        >Add New</LoadingButton>
                        {/* <Button onClick={downloadFile} variant='outlined'><Description sx={{ transform: "scale(1.3)", color: "primary.main" }} /></Button> */}
                        {/* <Button onClick={downloadFile} sx={{marginRight:"0.5rem"}}><Description sx={{ transform: "scale(1.3)", color: "primary.main" }} /></Button>  */}
                        <Button variant='outlined' onClick={downloadFile} sx={{ marginRight: "0.5rem" }}>Save File&nbsp;<Download /></Button>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Status"
                                onChange={() => {
                                    type == "Current" ? selectType("Current") : selectType("Submitted");
                                }}
                            >
                                <MenuItem component={Link} to={"/salespipeline/current/" + requested_year} disabled={type == "Current"} value="Current"><Typography variant='subtitle2'>
                                    Pipeline
                                </Typography></MenuItem>
                                <MenuItem component={Link} to={"/salespipeline/sale/" + requested_year} disabled={type == "Sale"} value="Sale"><Typography variant='subtitle2'>
                                    Sale
                                </Typography></MenuItem>

                            </Select>
                        </FormControl>

                    </Stack>
                    {addData ?

                        <Dialog
                            sx={{ height: "100vh" }}
                            maxWidth="lg"
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                        >
                            <DialogTitle id="edit-apartment">Add New Sales</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Please fill the required information.
                                </DialogContentText>
                                <Form prop={addData.message} type="sale" setData={setData} data={data} />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                                <Button sx={{ position: "absolute", top: 10, right: 20 }} onClick={handleClose} color="secondary">
                                    <Cancel sx={{ fontSize: "1.8rem" }} />
                                </Button>
                                <Button variant='contained' onClick={submitAddForm} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                        : <></>}

                    <Box mt={1}>
                        {/* <SaleTable table_data={currentSales.message} setotherAction={setotherAction} /> */}
                        <NewSaleTable table_data={currentSales.message} setotherAction={setotherAction} />
                        {graph ? <GraphReport data={graph} type='sale' /> : <></>}
                    </Box>

                </Box> :
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                        <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                        <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                        <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                    </Grid>

                    {/* <Button><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Button> */}
                    <Skeleton variant='rectangle' width="100%" height={350} sx={{ ...border, mt: "4rem" }} />
                </Box>
            }
        </Box>
    )
}
