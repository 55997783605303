import { Cancel } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite"
import axios from "../../api/axios";
import Notify from "../../features/helpers/notifyConfig";
import { LoadingButton } from "@mui/lab";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
  DateRangePicker;

const ApplyLeaveBox = ({ leaveData, setLeaveApplyBox }) => {

    const [applyLeaveBox, setApplyLeaveBox] = useState(false);
    const [openPTO, setOpenPTO] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [availableDates, setAvailableDates] = useState(null);
    const [resetState, setResetState] = useState(false);
    const [leaveSchema, setLeaveSchema] = useState({
        "StartDate": "",
        "EndDate": "",
        "NoOfDays": 1,
        "Reason": "",
        "LeaveType": "",
        "isEmergency": false
    });

    const handleClose = () => {
        setOpenPTO(false);
        // setOpenLeaveBox(false);
    }

    useEffect(() => {
        checkAvailableDates();
    }, [])

    const checkAvailableDates = async () => {
        await axios.get("/dashboard/timesheet/leave/add",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setAvailableDates(response?.data?.message?.Non_Available_Days);
            }).catch((error) => {
                setLeaveApplyBox(false);
                Notify.error("Some error occurred. Try Again!")
            })
    }

    const leaveApply = async () => {
        setSubmitStatus(true);
        await axios.post(`/dashboard/timesheet/leave/add`,
            JSON.stringify(leaveSchema),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                // getData();
                setLeaveApplyBox(false);
                Notify.success(`Your Leave Request has been sent to ${leaveData.reporting_to}!`);
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!")
            }).finally(() => {
                setSubmitStatus(false);
            })
    }

    const dateChange = (val) => {
        if (val != null) {
            const startDate = new Date(val[0]);
            const endDate = new Date(val[1]);
            var diff = endDate - startDate;
            diff = Math.floor(diff / (1000 * 60 * 60 * 24));
            const formattedStartDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
            const formattedEndDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
            setLeaveSchema({
                ...leaveSchema,
                "StartDate": formattedStartDate,
                "EndDate": formattedEndDate,
                "NoOfDays": diff + 1
            })
        }
    }

    const reasonChange = (e, val) => {
        setLeaveSchema({
            ...leaveSchema,
            "Reason": e.target.value
        })
    }

    const leaveType = (e, val) => {
        setLeaveSchema({
            ...leaveSchema,
            "LeaveType": val
        })
    }

    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-").map(Number);
        return new Date(year, month - 1, day); // Month is zero-based in JS Date
      };

    const isBlocked = (date) => {
        console.log(availableDates);
        return availableDates && availableDates.some(
          (availableDate) => date.toDateString() === parseDate(availableDate).toDateString()
        );
      };

    return (
        <Dialog
            sx={{ height: "100vh" }}
            fullWidth
            maxWidth="sm"
            open={"true"}
            onClose={() => setLeaveApplyBox(false)}
            aria-labelledby="edit-apartment"
        >
            {/* <DialogTitle id="edit-apartment">Apply for Leave</DialogTitle> */}
            <Box sx={{ marginBottom: "0", padding: '1.5rem 1.5rem 1rem', borderBottom: "1px solid gray" }}>
                <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700'>Apply for leave</Typography>
                <Typography mt={0.5} fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400' lineHeight={1.2} color="gray">If someone from your team has already applied or requested for leave then those days will be locked on date selector tab. You can only apply for the same day/s in case of emergency, just press <span style={{fontWeight: "600"}}>Reset Dates</span> button.</Typography>
            </Box>
            <DialogContent>
                <Stack direction='column' spacing={4}>
                    <Stack direction='column' spacing={0.3}>
                        <Typography fontWeight='600' color="gray">Leave Type<sup style={{ color: "red" }}>*</sup></Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={((e, val) => leaveType(e, val))}
                            >
                                <FormControlLabel
                                    style={{
                                        paddingRight: "1rem"
                                    }}
                                    disabled={leaveSchema.NoOfDays == 1 ? false : true} value="half day" control={<Radio />} label="Half-time" />
                                <FormControlLabel value="full day" control={<Radio />} label="Full-time" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>

                    <Stack direction='column' spacing={1}
                        style={{
                            marginTop: "1.3rem"
                        }}
                    >
                        <Typography fontWeight='600' color="gray">Select Dates<sup style={{ color: "red" }}>*</sup></Typography>
                        <DateRangePicker
                            className="leaveBox"
                            showHeader={false}
                            shouldDisableDate={resetState ? [] : isBlocked}
                            loading={availableDates === null}
                            onChange={(val) => dateChange(val)}
                            caretAs={EventAvailableIcon} />
                    </Stack>

                    <Stack direction='column' spacing={1}>
                        <Typography fontWeight='600' color="gray">Reason of absence<sup style={{ color: "red" }}>*</sup></Typography>
                        <TextField
                            multiline
                            sx={{
                                '& .MuiInputBase-input': {
                                    height: 'auto !important'
                                },
                                '& .MuiInputBase-root': {
                                    bgcolor: 'white',
                                    borderRadius: "7px !important"
                                }
                            }}
                            rows={5}
                            placeholder="Specify the reason for leave...."
                            onChange={(e, val) => reasonChange(e, val)}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button sx={{ position: "absolute", top: 10 }} onClick={() => setLeaveApplyBox(false)} color="secondary">
                    <Cancel sx={{ fontSize: "1.8rem" }} />
                </Button>
                <Stack direction="row" spacing={2}>
                    <Button
                        style={{
                            marginBottom: "0.5rem",
                            padding: "0.2rem 1rem"
                        }}
                        disabled={submitStatus}
                        onClick={() => {
                            const currentResetState = !resetState;
                            setResetState(currentResetState);
                            setLeaveSchema({
                                ...leaveSchema,
                                "isEmergency": currentResetState
                            })
                        }} color={resetState ? "warning" : "error"} variant="contained">
                        {resetState ? "Unreset Dates" : "Reset Dates"}
                    </Button>
                    <LoadingButton
                        style={{
                            marginRight: "0.9rem",
                            marginBottom: "0.5rem",
                            padding: "0.2rem 1.8rem"
                        }}
                        variant="contained" loading={submitStatus} onClick={leaveApply} color="primary">
                        Apply
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog >
    )
}

export default ApplyLeaveBox